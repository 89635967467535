<template>
  <div class="mainContent db-page">
    <div class="top-control">
      <div class="tab-content">
        <!-- <div class="dashboard-hpo" v-if="renderComponent">
          <muuri-grid id="grid" ref="muurigrid" :options="{dragEnabled: false}">
             <muuri-grid-item width="50%">
                <advance-payment v-if="adminRole"></advance-payment>
             </muuri-grid-item>
             <muuri-grid-item width="50%">
                <wallet-summary v-if="adminRole"></wallet-summary>
             </muuri-grid-item>
          </muuri-grid>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import configs from '@/configs';
// import {mapState} from 'vuex';
import clickoutside from '@/directives/clickoutside';
// import AdvancePayment from '@/components/dashboard/AdvancePayment';
// import WalletSummary from '@/components/dashboard/WalletSummary';
// import { MuuriGridItem } from 'vue-muuri-grid';

export default {
  directives: { clickoutside },
  // components:{
  //   AdvancePayment,
  //   WalletSummary,
  //   MuuriGridItem,
  // },
  computed: {
    adminRole() {
      return (this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.Admin) == configs.ROLE_TYPE.Admin;
    },
  },
  data() {
    return {
      showModal: false,
      renderComponent: true,
      popupModalName: '',
      popupModalTitle: '',
      popupModalHeight: 600,
    }
  },
  mounted() {
    this.$root.$on('zoomChart', (widget) => {
      this.popupModalName = widget.widgetName;
      this.popupModalTitle = widget.widgetTitle;
      this.popupModalHeight = window.innerHeight * 0.6;
    });
  }
}
</script>
<style>
.modal-open{padding-right: 0 !important; }
.modal-backdrop{ z-index: 999998;}
.close:focus{outline:none}
</style>