<template>
<v-app id="login" class="primary">
    <v-container fluid fill-height>
        <v-layout align-center justify-center>
            <v-flex xs12 sm8 md4 lg4>
                <v-card class="elevation-1 pa-3">
                    <v-card-text class="justify-center">
                        <div class="layout column align-center">
                            <img src="/static/v-logo.png" alt="viettaobao Admin" height="64">
                        </div>
                        <v-form>
                            <v-text-field append-icon="fa-user" name="login" label="Tên đăng nhập" placeholder=" " type="text" v-model="model.username" @keyup.enter="login"></v-text-field>
                            <v-text-field append-icon="fa-key" name="password" label="Mật khẩu" placeholder=" " id="password" type="password" v-model="model.password" @keyup.enter="login"></v-text-field>
                        </v-form>
                    </v-card-text>
                    <v-card-actions class="layout column align-center">
                        <v-spacer></v-spacer>
                        <v-btn @click="login" :loading="loading" class="white--text oee-theme" width="120px">Login</v-btn>
                    </v-card-actions>
                    <v-snackbar color="error" v-model="snackbar" :timeout="6000" :top="true">
                        {{ message }}
                        <v-btn color="white" text @click="snackbar = false">
                            X
                        </v-btn>
                    </v-snackbar>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</v-app>
</template>

<script>
import {
    mapState
} from "vuex";

export default {
    computed: {
        ...mapState({
            token: state => state.authentication.token,
            resData: state => state.authentication.resData,
        }),
    },
    data: () => ({
        loading: false,
        model: {
            username: '',
            password: ''
        },
        snackbar: false,
        message: ''
    }),
    watch: {
        resData() {
            if (this.resData && this.resData.status_code == 400) {
                this.snackbar = true;
                this.message = 'Tài khoản hoặc mật khẩu không đúng.'
            } else if (this.token && this.token !== '') {
                window.getApp.$emit('APP_LOGIN_SUCCESS');
            }
            this.loading = false;
        }
    },
    methods: {
        login() {
            this.loading = true;
            this.$store.dispatch('authentication/login', this.model);
        }
    }

};
</script>

<style lang="css" scoped>
#login {
    height: 50%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    z-index: 0;
}
</style>
